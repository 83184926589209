define(['debounce', 'viewport', '$window'], function(debounce, viewport, $window) {

  /*
   * fire a callback when an element appears in the viewport
   * only works for vertical scrolling on the window
   */
  var elementViewed = function(element, callback) {
    var debounceTime = 500;
    var _checkTracking = function(element) {
      //check against each tracked element
      for (var i = $window.viewTracker.length - 1; i >= 0; i--) {
        if (viewport.isElementVisible($window.viewTracker[i].element)) {
          $window.viewTracker[i].callback.apply();
          $window.viewTracker.splice(i, 1); // remove tracked element
        }
      }
    };

    if (viewport.isElementVisible(element)) {
      callback.apply();
    } else {
      // setup globals if not already present
      if (!$window.viewTracker) {
        $window.viewTracker = [];
        var debouncedHandler = debounce(_checkTracking, debounceTime);
        document.addEventListener('scroll', debouncedHandler);
      }

      $window.viewTracker.push({
        element: element,
        callback: callback
      });
    }

  };

  return elementViewed;
});
